<template>
    <front-layout>
        <v-container class="fill-height">
            <v-row class="text-center">
                <v-card max-width="800" min-width="600" class="mx-auto px-5 py-5">
                    <v-card-text>
                        <v-row class="text-center">
                            <v-col cols="12">
                                <v-list subheader>
                                    <v-subheader>Uploaded Files</v-subheader>

                                    <v-list-item v-for="file in files" :key="file.url">
                                        <v-list-item-avatar size="64">
                                            <v-img :src="file.url"/>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                To: {{ file.to }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-icon>
                                            <v-btn :loading="loading"
                                                   :disabled="! file.download_url"
                                                   target="_blank"
                                                   :href="file.download_url"
                                                   v-text="'Download'"/>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <v-btn @click="handleDownload"
                                       :loading="loading"
                                       :disabled="finished || files.length === 0"
                                       v-text="'Convert and Download'"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import ConversionApi from "../api/ConversionApi";
    import { mapMutations} from "vuex";

    export default {
        name: 'Convert',
        components: {FrontLayout},
        data: function () {
            return {
                loading: false,
                finished: false,
                files: [],
            }
        },
        methods: {
            ...mapMutations(['showSnackBar']),

            async handleDownload() {
                this.loading = true;

                try {
                    const response = await ConversionApi.convertPublic(this.files, !!this.$auth.user());
                    this.files = response.data
                    await this.$store.dispatch('myFiles/pushMultipleFiles', {files: this.files})
                    this.loading = false;
                    this.finished = true;
                    this.$forceUpdate()
                } catch (error) {
                    this.showSnackBar({color: 'error', timeout: 3000, text: error.response.data.message})
                    this.loading = false;
                }
            }
        },
        mounted() {
            this.files = this.$route.params.files || [];
        }
    }
</script>

<style scoped>

</style>
